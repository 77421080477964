import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    body1: {
      fontSize: "1.15rem",
      fontWeight: 500,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "black",
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiInput-root:after": {
            borderBottomColor: "black",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "black",
          "&.MuiInputLabel-root": {
            "&.MuiInputLabel-root": {
              color: "black",
            },
            "&.Mui-focused": {
              color: "black",
            },
          },
        },
      },
    },
  },
});

export default theme;
