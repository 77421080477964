import React, { useContext } from "react";
import "./TopNavBar.scss";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { Menu as MenuIcon } from "@mui/icons-material";
import { HideOnScroll, type HideOnScrollProps } from "./HideOnScroll";
import { Box, CssBaseline } from "@mui/material";
import { ReactComponent as BrinsHMLogo } from "../../assets/brins-hm-logo.svg";
import { AppContext } from "../../context";
import { useNavigate } from "react-router-dom";

interface NavbarProps extends Pick<HideOnScrollProps, "window"> {}

const TopNavBar = (props: NavbarProps) => {
  const navigate = useNavigate();
  const { isSideBarOpen, setIsSidebarOpen } = useContext(AppContext);

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar className="top-nav-bar" position="sticky">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box onClick={() => navigate("/")} sx={{ cursor: "pointer" }}>
              <BrinsHMLogo className="navbar-logo-icon" />
            </Box>

            <IconButton
              size="large"
              color="inherit"
              aria-label="menu"
              onClick={() => setIsSidebarOpen?.(!isSideBarOpen)}
            >
              <MenuIcon className="navbar-menu-icon" />
            </IconButton>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </React.Fragment>
  );
};

export default TopNavBar;
