import { Box, Grid } from "@mui/material";
import { ContentBoundary } from "../ContentBoundary";

const ServiceDescriptionSection: React.FC<ServiceDescriptionSectionProps> = ({
  backgroundColor,
  backgroundImageUrl,
  direction = "left",
  textColor,
  titleColor,
  title,
  description,
}) => {
  return (
    <Box sx={{ position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          display: "flex",
          bottom: "0px",
        }}
      >
        <Box
          sx={{
            backgroundColor,
            order: direction === "left" ? 0 : 1,
            flexBasis: { xs: "100%", md: "50%" },
          }}
        />
        <Box
          sx={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundSize: "cover",
            flexBasis: "50%",
            display: { xs: "none", md: "block" },
          }}
        ></Box>
      </Box>
      <Box sx={{ zIndex: 1, position: "relative" }}>
        <ContentBoundary>
          <Box sx={{ display: "flex", gap: 6 }}>
            <Box
              sx={{
                flexBasis: { xs: "100%", md: "50%" },
                order: direction === "left" ? 0 : 1,
              }}
            >
              <Grid container direction="column" gap={2}>
                <Grid item>
                  <Box
                    sx={{
                      typography: { xs: "h4", md: "h3" },
                    }}
                  >
                    <Box sx={{ fontWeight: "bold" }} color={titleColor}>
                      {title}
                    </Box>
                  </Box>
                </Grid>
                <Grid item>
                  <Box color={textColor}>{description}</Box>
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{ flexBasis: "50%", display: { xs: "none", md: "block" } }}
            ></Box>
          </Box>
        </ContentBoundary>
      </Box>
    </Box>
  );
};

export interface ServiceDescriptionSectionProps {
  backgroundColor: string;
  backgroundImageUrl: string;
  direction: "left" | "right";
  textColor: string;
  title: string;
  titleColor: string;
  description: string | React.ReactElement;
}

export default ServiceDescriptionSection;
