import { Box, Divider, Grid, Typography } from "@mui/material";
import styleVariables from "../../variables.module.scss";
import { MapLocation } from "../MapLocation";
import { ContentBoundary } from "../ContentBoundary";
import { EmailForm } from "../EmailForm";
import { SocialMediaIcons } from "../SocialMediaIcons";

const ContactSection = () => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: styleVariables.themeGreyColor,
          width: "100%",
          color: "white",
        }}
      >
        <ContentBoundary>
          <Grid
            direction="column"
            container
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Grid item>
              <Box
                sx={{
                  typography: { xs: "h6", md: "h3" },
                }}
              >
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: styleVariables.themeOrangeColor,
                  }}
                >
                  GET IN TOUCH
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{
                  borderBottomWidth: 4,
                  margin: 0,
                  borderColor: styleVariables.themeOrangeColor,
                  width: "30px",
                }}
              />
            </Grid>
            <Grid item>
              <Box
                sx={{
                  typography: { xs: "h4", md: "h1" },
                }}
              >
                <Box
                  sx={{
                    fontWeight: "bold",
                    color: styleVariables.themeOrangeColor,
                  }}
                >
                  CONTACT US
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ContentBoundary>
      </Box>
      <Box>
        <ContentBoundary>
          <Grid
            container
            direction={{ xs: "column", md: "row" }}
            gap={4}
            flexWrap="nowrap"
          >
            <Grid item xs={12} md={6}>
              <Grid
                container
                direction="column"
                gap={2}
                alignContent={{ xs: "center", md: "flex-start" }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    textAlign={{ xs: "center", md: "left" }}
                  >
                    Are you ready to experience the difference with Brin's
                    Handyman Services? Brin, our reliable and professional
                    handyman, is here to assist you with friendly service and
                    expert advice.
                  </Typography>
                </Grid>
                <Grid item>
                  <Box sx={{ height: "300px" }}>
                    <MapLocation />
                  </Box>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    justifyContent={{ xs: "center", md: "flex-start" }}
                  >
                    <Box width="fit-content">
                      <SocialMediaIcons />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{ backgroundColor: styleVariables.themeOrangeColor }}
                p={{ xs: 4, md: 6 }}
              >
                <EmailForm />
              </Box>
            </Grid>
          </Grid>
        </ContentBoundary>
      </Box>
    </Box>
  );
};

export default ContactSection;
