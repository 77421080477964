import { useContext } from "react";
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { AppContext } from "../../context";
import { SocialMediaIcons } from "../SocialMediaIcons";
import { useNavigate } from "react-router-dom";

const SideBar = () => {
  const navigate = useNavigate();
  const { isSideBarOpen, setIsSidebarOpen } = useContext(AppContext);

  const handleNavigationClick = (path: string) => {
    setIsSidebarOpen?.(false);
    navigate(path);
  };

  return (
    <Drawer
      anchor="right"
      open={isSideBarOpen}
      onClose={() => setIsSidebarOpen?.(false)}
      ModalProps={{
        slotProps: {
          backdrop: { sx: { backgroundColor: "rgba(0, 0, 0, 0.2)" } },
        },
      }}
      sx={{
        width: "300px",
      }}
      transitionDuration={{
        appear: 300,
        enter: 600,
        exit: 300,
      }}
    >
      <Box sx={{ width: { md: "350px", xs: "100dvw" } }}>
        <Toolbar sx={{ justifyContent: "flex-end" }}>
          <IconButton
            size="large"
            color="inherit"
            aria-label="side-bar"
            onClick={() => setIsSidebarOpen?.(false)}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <List>
          <ListItem>
            <ListItemButton
              sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
              onClick={() => handleNavigationClick("/")}
            >
              <Typography variant="h6">Home</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
              onClick={() => handleNavigationClick("/services")}
            >
              <Typography variant="h6">Services</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              sx={{ justifyContent: { xs: "center", md: "flex-start" } }}
              onClick={() => handleNavigationClick("/contact us")}
            >
              <Typography variant="h6">Contact Us</Typography>
            </ListItemButton>
          </ListItem>
        </List>
        <Box pl={2} pr={2} pt={4} pb={4}>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            sx={{ borderBottomWidth: 1, margin: 0, borderColor: "black" }}
          />
        </Box>
        <Grid container direction="column" pl={2} pr={2}>
          <Grid item>
            <Grid container justifyContent="center">
              <Box>
                <SocialMediaIcons color="black" />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default SideBar;
