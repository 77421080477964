import {
  ContactSection,
  ServicesListSection,
  TopSection,
} from "../../components";

const SERVICES = [
  "Home Repairs",
  "Painting",
  "Drywall Installation and Repair",
  "Plumbing & Pipes",
  "Carpentry",
  "Furniture Repairs",
];

const HomePage = () => {
  return (
    <div>
      <TopSection
        subTitle="WELCOME TO"
        mainTitle="Brin's Handyman Services"
        content="Your Trusted Source for Professional Handyman Services in
                  Ottawa and Surrounding Areas Are you in need of reliable,
                  skilled, and affordable handyman services in Ottawa and its
                  neighboring areas? Look no further than Brin's Handyman
                  Services!"
      />
      <ServicesListSection services={SERVICES} />
      <ContactSection />
    </div>
  );
};

export default HomePage;
