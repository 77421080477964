import {
  ContactSection,
  ServiceDescriptionSection,
  TopSection,
} from "../../components";
import styleVariables from "../../variables.module.scss";
import landingPageImage from "../../assets/landing-page.jpg";

const SERVICES_CONTENT = [
  {
    title: "Home Repairs",
    description:
      "Keep your home in top condition with Brin's Handyman Services. I handle all types of home repairs, from fixing leaky faucets to patching up drywall, ensuring your home stays safe and functional. No job is too big or small, and each repair is done with quality and care.",
  },
  {
    title: "Painting",
    description:
      "Transform your space with professional painting services from Brin's Handyman Services. Whether it's a single room or your entire home, I provide meticulous and high-quality painting, ensuring a fresh and vibrant look for your interiors and exteriors.",
  },
  {
    title: "Drywall Installation and Repair",
    description:
      "Enhance your home with expert drywall installation and repair from Brin's Handyman Services. Whether you need new drywall installed or existing walls repaired, I ensure seamless and professional results, leaving your walls smooth and ready for painting or finishing.",
  },
  {
    title: "Plumbing & Pipes",
    description:
      "Ensure your plumbing is in perfect working order with Brin's Handyman Services. I handle everything from fixing leaks and unclogging drains to installing new pipes, providing reliable and efficient plumbing solutions for your home.",
  },
  {
    title: "Carpentry",
    description:
      "Add quality and craftsmanship to your home with Brin's Handyman Services. I offer a range of carpentry services, including custom shelves, cabinetry, and repairs, ensuring precision and attention to detail in every project.",
  },
  {
    title: "Furniture Repairs",
    description:
      "Restore your furniture to its original beauty with Brin's Handyman Services. I provide expert repairs for all types of furniture, from fixing broken legs to refinishing surfaces, ensuring your pieces are sturdy and look their best.",
  },
];

const ServicesPage = () => {
  return (
    <div>
      <TopSection
        subTitle="JUST A PHONE CALL AWAY"
        mainTitle="Our Services"
        content="No matter where you are in the bustling city of Ottawa or its peaceful suburbs, Brin's Handyman Services is here to serve you. From downtown Ottawa to the outskirts, our exceptional services are just a phone call away."
      />
      {SERVICES_CONTENT.map(({ title, description }, index) => {
        const direction = index % 2 ? "left" : "right";
        const textColor = index % 2 ? "black" : "white";
        const titleColor =
          index % 2 ? "black" : styleVariables.themeOrangeColor;
        const backgroundColor =
          index % 2
            ? styleVariables.themeOrangeColor
            : styleVariables.themeGreyColor;
        return (
          <ServiceDescriptionSection
            key={title}
            backgroundColor={backgroundColor}
            backgroundImageUrl={landingPageImage}
            textColor={textColor}
            titleColor={titleColor}
            direction={direction}
            title={title}
            description={description}
          />
        );
      })}
      <ContactSection />
    </div>
  );
};

export default ServicesPage;
