import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import { BorderColorButton } from "../BorderColorButton";
import { grey } from "@mui/material/colors";
import { FormEvent, useRef } from "react";

const {
  REACT_APP_EMAIL_SERVICE = "",
  REACT_APP_EMAIL_TEMPLATE = "",
  REACT_APP_EMAIL_PUBLIC_K = "",
} = process.env;

const EmailForm = () => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    debugger;
    emailjs
      .sendForm(
        REACT_APP_EMAIL_SERVICE,
        REACT_APP_EMAIL_TEMPLATE,
        formRef?.current as HTMLFormElement,
        {
          publicKey: REACT_APP_EMAIL_PUBLIC_K,
        }
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Message sent successfully!");
        },
        (error) => {
          console.error(error.text);
          alert("Error sending message. Please try again later.");
        }
      );
  };

  return (
    <Grid container direction="column" gap={2}>
      <Grid item>
        <Box
          sx={{
            typography: { xs: "h6", md: "h4" },
          }}
        >
          <Box
            sx={{ fontWeight: "bold" }}
            textAlign={{ xs: "center", md: "left" }}
          >
            REQUEST A FREE QUOTE
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <form onSubmit={handleSubmit} ref={formRef}>
          <Grid container direction="column" gap={2}>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel required htmlFor="name-input">
                  Your Name
                </InputLabel>
                <Input
                  id="name-input"
                  aria-describedby="name-text"
                  name="name"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel required htmlFor="email-to-address">
                  Email address
                </InputLabel>
                <Input
                  id="email-to-address"
                  aria-describedby="email-to-address-text"
                  name="email"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel required htmlFor="phone-number">
                  Phone Number
                </InputLabel>
                <Input
                  id="phone-number"
                  type="number"
                  aria-describedby="phone-number"
                  name="phone"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel required htmlFor="personal-address">
                  Address
                </InputLabel>
                <Input
                  id="personal-address"
                  type="number"
                  aria-describedby="personal-address"
                  name="address"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <InputLabel required htmlFor="email-subject">
                  Subject
                </InputLabel>
                <Input
                  id="email-subject"
                  aria-describedby="email-subject-text"
                  name="subject"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl fullWidth>
                <FormLabel required>How May We Help You?</FormLabel>
                <TextField
                  id="email-content"
                  aria-describedby="email-content-text"
                  minRows={5}
                  maxRows={5}
                  multiline
                  name="message"
                />
              </FormControl>
            </Grid>
            <Grid item>
              <BorderColorButton
                buttonBorderColor="black"
                buttonColor="black"
                buttonBorderColorHover={grey[900]}
                buttColorHover={grey[900]}
                type="submit"
                variant="outlined"
                size="large"
                sx={{ mt: 2 }}
              >
                Get A Quote
              </BorderColorButton>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default EmailForm;
