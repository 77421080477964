import {
  Box,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styleVariables from "../../variables.module.scss";
import { LocalPhone as LocalPhoneIcon } from "@mui/icons-material";
import { Parallax } from "../Parallax";
import landingPageImage from "../../assets/landing-page.jpg";
import { ContentBoundary } from "../ContentBoundary";
import { BorderColorButton } from "../BorderColorButton";

const TopSection: React.FC<TopSectionProps> = ({
  subTitle = "",
  mainTitle = "",
  content = "",
}) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.up("xs"));
  return (
    <Box sx={{ width: "100%" }}>
      <Parallax
        bgImage={landingPageImage}
        strength={mobile ? 100 : 250200}
        bgImageStyle={{
          objectFit: "cover",
          width: "100%",
          heading: "auto",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#000000",
            opacity: { xs: 0.7, md: 0.5 },
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
          }}
        />
        <ContentBoundary>
          <Box
            sx={{
              color: "white",
              height: { md: "fit-content" },
              width: { md: "450px" },
              zIndex: 1,
            }}
          >
            <Grid
              container
              direction="column"
              gap={2}
              alignItems={{ xs: "center", md: "flex-start" }}
            >
              <Grid item>
                <Box
                  sx={{
                    typography: { xs: "h6", md: "h3" },
                  }}
                >
                  <Box
                    sx={{
                      fontWeight: "bold",
                      color: styleVariables.themeOrangeColor,
                    }}
                  >
                    {subTitle}
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Box pl={{ md: 2 }}>
                  <Divider
                    orientation="horizontal"
                    variant="middle"
                    flexItem
                    sx={{
                      borderBottomWidth: 4,
                      margin: 0,
                      borderColor: styleVariables.themeOrangeColor,
                      width: "30px",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box
                  sx={{
                    typography: { xs: "h4", md: "h1" },
                  }}
                >
                  <Box
                    sx={{ fontWeight: "bold" }}
                    textAlign={{ xs: "center", md: "left" }}
                    color={styleVariables.themeOrangeColor}
                  >
                    {mainTitle}
                  </Box>
                </Box>
              </Grid>
              <Grid item>
                <Typography textAlign={{ xs: "center", md: "left" }}>
                  {content}
                </Typography>
              </Grid>
              <Grid item>
                <BorderColorButton
                  variant="outlined"
                  size="large"
                  sx={{ mt: 2 }}
                  buttonColor={styleVariables.themeOrangeColor}
                  buttonBorderColor={styleVariables.themeOrangeColor}
                  buttColorHover={styleVariables.themeOrange2Color}
                  buttonBorderColorHover={styleVariables.themeOrange2Color}
                >
                  <LocalPhoneIcon />
                  &nbsp;&nbsp;Contact Us
                </BorderColorButton>
              </Grid>
            </Grid>
          </Box>
        </ContentBoundary>
      </Parallax>
    </Box>
  );
};

export interface TopSectionProps {
  subTitle?: string;
  mainTitle?: string;
  content?: string;
}

export default TopSection;
