import styled from "@emotion/styled";
import { Button, ButtonProps } from "@mui/material";
import { grey } from "@mui/material/colors";
import { forwardRef, useMemo } from "react";

const BorderColorButton = forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    buttonBorderColor?: string;
    buttonBorderColorHover?: string;
    buttonColor?: string;
    buttColorHover?: string;
  }
>(
  (
    {
      buttonColor = "white",
      buttonBorderColor = "white",
      buttColorHover = grey[300],
      buttonBorderColorHover = grey[300],
      children,
      ...restButtonProps
    },
    ref
  ) => {
    const StyledButton = useMemo(() => {
      return styled(Button)<ButtonProps>(({ theme }) => ({
        color: buttonColor,
        borderColor: buttonBorderColor,
        "&:hover": {
          color: buttColorHover,
          borderColor: buttonBorderColorHover,
        },
      }));
    }, [
      buttonColor,
      buttColorHover,
      buttonBorderColor,
      buttonBorderColorHover,
    ]);

    return (
      <StyledButton ref={ref} {...restButtonProps}>
        {children}
      </StyledButton>
    );
  }
);

BorderColorButton.displayName = "BorderColorButton";

export default BorderColorButton;
