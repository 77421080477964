import React from "react";
import "./App.scss";
import { Footer, SideBar, TopNavBar } from "./components";
import { Box } from "@mui/material";
import { AppContextProvider } from "./context";
import { HomePage, ServicesPage } from "./pages";
import { Navigate, Route, Routes } from "react-router-dom";
import { useScrollTop } from "./hooks";

function App() {
  useScrollTop();
  return (
    <AppContextProvider>
      <TopNavBar />
      <SideBar />
      <Box className="page-content">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Box>
      <Footer />
    </AppContextProvider>
  );
}

export default App;
