import { Box, Divider, Grid, Typography } from "@mui/material";
import { Check as CheckIcon } from "@mui/icons-material";
import styleVariables from "../../variables.module.scss";
import { ContentBoundary } from "../ContentBoundary";
import { BorderColorButton } from "../BorderColorButton";
import { useNavigate } from "react-router-dom";

const ServicesListSection = ({ services = [] }: { services: string[] }) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: styleVariables.themeGreyColor,
          width: "100%",
          color: "white",
        }}
      >
        <ContentBoundary>
          <Grid
            direction="column"
            container
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Grid item>
              <Box
                sx={{
                  typography: { xs: "h6", md: "h3" },
                }}
              >
                <Box
                  sx={{ fontWeight: "bold" }}
                  color={styleVariables.themeOrangeColor}
                >
                  JUST A PHONE CALL AWAY
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Divider
                orientation="horizontal"
                variant="middle"
                flexItem
                sx={{
                  borderBottomWidth: 4,
                  margin: 0,
                  borderColor: styleVariables.themeOrangeColor,
                  width: "30px",
                }}
              />
            </Grid>
            <Grid item>
              <Box
                sx={{
                  typography: { xs: "h4", md: "h1" },
                }}
              >
                <Box
                  sx={{ fontWeight: "bold" }}
                  color={styleVariables.themeOrangeColor}
                >
                  OUR SERVICES
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ContentBoundary>
      </Box>
      <Box
        sx={{
          backgroundColor: styleVariables.themeOrangeColor,
          color: "black",
        }}
      >
        <ContentBoundary>
          <Grid
            container
            direction={{ xs: "column", md: "row" }}
            gap={4}
            flexWrap="nowrap"
          >
            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <Grid container gap={2}>
                {services.map((service) => (
                  <Grid item key={service}>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Box
                        pr={1}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <CheckIcon />
                      </Box>
                      <Typography variant="h6">{service}</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <Grid
                container
                direction="column"
                alignItems={{ xs: "center", md: "flex-start" }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    textAlign={{ xs: "center", md: "left" }}
                  >
                    At Brin's Handyman Services, we offer a wide range of repair
                    services to our clients. Our services are tailored to
                    simplify the completion of your next project. Some of the
                    repair services we offer include:
                  </Typography>
                </Grid>
                <Grid item>
                  <BorderColorButton
                    buttonColor="black"
                    buttonBorderColor="black"
                    buttColorHover={styleVariables.themeGreyColor}
                    buttonBorderColorHover={styleVariables.themeGreyColor}
                    variant="outlined"
                    size="large"
                    sx={{ mt: 2 }}
                    onClick={() => navigate("/services")}
                  >
                    Read More
                  </BorderColorButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ContentBoundary>
      </Box>
    </Box>
  );
};

export default ServicesListSection;
