import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import "./ContentBoundary.scss";

const ContentBoundary = ({ children }: PropsWithChildren) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        className="content-boundary"
        sx={{
          pl: { xs: 4, md: 25 },
          pr: { xs: 4, md: 25 },
          pt: { xs: 2, md: 10 },
          pb: { xs: 2, md: 10 },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ContentBoundary;
