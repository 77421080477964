import { Box } from "@mui/material";

const MapLocation = () => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <iframe
        title="store-locator"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d359537.03691581567!2d-75.8002569!3d45.2501566!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xaa001245c0a3cdbd%3A0xfc8d67974fbc229c!2sBrin&#39;s%20Handyman%20Services!5e0!3m2!1sen!2sca!4v1719339767734!5m2!1sen!2sca"
        width="100%"
        height="100%"
        style={{ border: "0px" }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </Box>
  );
};

export default MapLocation;
