import {
  Grid,
  IconButton,
  SvgIcon,
  type IconButtonOwnProps,
} from "@mui/material";
import {
  WhatsApp as WhatsAppIcon,
  Google as GoogleIcon,
  LinkedIn as LinkedInIcon,
} from "@mui/icons-material";
import { ReactComponent as KijijiIcon } from "../../assets/kijiji-logo.svg";

const SocialMediaIcons = ({
  color,
}: {
  color?: IconButtonOwnProps["color"] | string;
}) => {
  return (
    <Grid container color={color} gap={1}>
      <Grid item>
        <IconButton
          color="inherit"
          size="large"
          onClick={() => {
            window.open(`https://wa.me/+16132764562`, "_blank");
          }}
        >
          <WhatsAppIcon fontSize="large" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color="inherit"
          size="large"
          onClick={() => {
            window.open(`https://kijiji.ca/o-profile/1012624541`, "_blank");
          }}
        >
          <SvgIcon component={KijijiIcon} inheritViewBox fontSize="large" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color="inherit"
          size="large"
          onClick={() => {
            window.open(`https://g.co/kgs/wNRzmw2`, "_blank");
          }}
        >
          <GoogleIcon fontSize="large" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton
          color="inherit"
          size="large"
          onClick={() => {
            window.open(
              `https://www.linkedin.com/in/michaelbrin/?locale=en_US`,
              "_blank"
            );
          }}
        >
          <LinkedInIcon fontSize="large" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default SocialMediaIcons;
