import {
  Box,
  Divider,
  Grid,
  Link as MUILink,
  Typography,
  styled,
} from "@mui/material";
import { SocialMediaIcons } from "../SocialMediaIcons";
import styleVariables from "../../variables.module.scss";
import { Link, LinkProps } from "react-router-dom";

export interface FooterProps {}

const CleanLink = styled(Link)<LinkProps>(({ theme }) => ({
  textDecoration: "none",
  color: "inherit",
}));

export default function Footer(props: FooterProps) {
  return (
    <Box
      sx={{ backgroundColor: styleVariables.themeGreyColor, color: "white" }}
    >
      <Box alignItems="center" justifyContent="center" display="flex">
        <Box
          maxWidth="1980px"
          width="100%"
          sx={{
            pl: { xs: 4, md: 25 },
            pr: { xs: 4, md: 25 },
            pt: { xs: 2, md: 10 },
            pb: { xs: 2, md: 10 },
          }}
        >
          <Grid
            container
            sx={{ flexDirection: { sm: "column", md: "row", xs: "column" } }}
            columnSpacing={{ md: 4 }}
            rowSpacing={{ sm: 5, xs: 2 }}
            m={0}
            width="100%"
            alignItems="center"
          >
            <Grid item md={6} width={{ xs: "100%", sm: "100%" }}>
              <Grid
                container
                direction="column"
                alignItems={{ sm: "center", xs: "center", md: "flex-start" }}
              >
                <Typography
                  mb={5}
                  fontWeight={700}
                  variant="h4"
                  color={styleVariables.themeOrangeColor}
                >
                  Keep in touch
                </Typography>
                <Grid
                  container
                  direction="column"
                  rowSpacing={2}
                  alignItems={{ sm: "center", xs: "center", md: "flex-start" }}
                >
                  <Grid item>
                    <Typography>
                      <MUILink
                        href="mailto:brinshandymanservices@gmail.com"
                        underline="none"
                        color="white"
                      >
                        brinshandymanservices@gmail.com
                      </MUILink>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <MUILink
                        href="tel:+1-613-276-4562"
                        underline="none"
                        color="white"
                      >
                        +1 (613) 276-4562
                      </MUILink>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <SocialMediaIcons color={styleVariables.themeOrangeColor} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} width={{ xs: "100%", sm: "100%" }}>
              <Grid
                container
                direction="column"
                rowSpacing={4}
                alignItems={{ sm: "center", xs: "center", md: "flex-start" }}
              >
                <Grid item>
                  <CleanLink to="/" color="white">
                    <Typography variant="h6">Home</Typography>
                  </CleanLink>
                </Grid>
                <Grid item>
                  <CleanLink to="/services">
                    <Box color="white">
                      <Typography variant="h6">Services</Typography>
                    </Box>
                  </CleanLink>
                </Grid>
                <Grid item>
                  <CleanLink to="/contact us" color="white">
                    <Typography variant="h6">Contact Us</Typography>
                  </CleanLink>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box sx={{ pt: { xs: 4, md: 10 }, pb: { xs: 4, md: 10 } }}>
            <Divider
              orientation="horizontal"
              variant="middle"
              flexItem
              sx={{
                borderBottomWidth: 1,
                margin: 0,
                borderColor: styleVariables.themeOrangeColor,
              }}
            />
          </Box>
          <Box textAlign={{ sm: "center", xs: "center", md: "left" }}>
            <Typography>Copyright 2024 © Michael Brin</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
