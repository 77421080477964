import React, { createContext, useState } from "react";

export const AppContext = createContext<{
  isSideBarOpen?: boolean;
  setIsSidebarOpen?: (state: boolean) => void;
}>({});

export const AppContextProvider = ({ children }: React.PropsWithChildren) => {
  const [isSideBarOpen, setIsSidebarOpen] = useState(false);

  const initialState = {
    isSideBarOpen,
    setIsSidebarOpen,
  };

  return (
    <AppContext.Provider value={initialState}>{children}</AppContext.Provider>
  );
};
